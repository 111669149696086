/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router'
import store from "../store";
//import { ElNotification } from 'element-plus'
import Cookies from 'js-cookie';
import axiosIns from '../plugins/axios';
import helpers from '../helpers';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../components/accueil/Accueil.vue'),
    meta: {
      BeforeAuth: true
    },
  },
  {
    path: '/validation_email',
    name: 'ErrorVerification',
    component: () => import('../components/tools/ErrorVerification.vue'),
    meta: {
      Auth: true
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/login/Login.vue'),
    meta: {
      BeforeAuth: true
    },
  },
  {
    path: '/forget_password',
    name: 'ForgetPassword',
    component: () => import('../components/login/ForgetPassword.vue'),
    meta: {
      BeforeAuth: true
    },
  },
  {
    path: '/email',
    name: 'EmailVerify',
    component: () => import('../components/register/EmailVerify.vue')
  },
  {
    path: '/reset_password',
    name: 'ResetPassword',
    component: () => import('../components/login/ResetPassword.vue'),
    meta: {
      BeforeAuth: true
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../components/register/Register.vue'),
    meta: {
      BeforeAuth: true
    },
  },
  
  
  {
    path: '/specialityear',
    name: 'Speciality&Year',
    component: () => import('../components/dashboard/Speciality&Year.vue'),
    meta: {
      Verified: true,
      Auth: true,
    }
  },



  {
    path: "/dashboard",
    name: 'Dashboard',
    component: () => import('../components/dashboard/Dashboard.vue'),
    meta: {
      Auth: true,
      Sub: true
    },
    children: [
      {
        path: '',
        name: 'MainDashboard',
        component: () => import('../components/dashboard/MainDashboard.vue'),
        meta: {
          Verified: true
        }
      },
      {
        path: 'account',
        name: 'Account',
        component: () => import('../components/dashboard/Account.vue'),
        meta: {
          Verified: true
        }
      },
      {
        path: 'questions',
        name: 'Questions',
        component: () => import('../components/dashboard/Questions.vue'),
        meta: {
          SharedAdmin: true,
          Verified: true
        },
      },
      {
        path: 'exams',
        name: 'Exams',
        component: () => import('../components/dashboard/Exams.vue'),
        meta: {
          SharedAdmin: true,
          Verified: true
        },
      },
      {
        path: 'residanat',
        name: 'residanat',
        title: "Annales Résidanat",
        component: () => import('../components/dashboard/Residana/admin/index.vue'),
        meta: {
          SharedAdmin: true,
          Verified: true
        },
      },
      {
        path: 'students',
        name: 'Studens',
        component: () => import('../components/dashboard/Students.vue'),
        meta: {
          SuperAdmin: true,
          Verified: true
        },
      },
      {
        path: 'students/:id',
        name: 'StudentDetails',
        component: () => import('../components/dashboard/StudentDetails.vue'),
        meta: {
          SuperAdmin: true,
          Verified: true
        },
      },
      {
        path: 'addquestions',
        name: 'AddQuestions',
        component: () => import('../components/dashboard/AddQuestions.vue'),
        meta: {
          SharedAdmin: true,
          Verified: true
        },
      },
      {
        path: 'addquestionsform',
        name: 'AddQuestionForm',
        component: () => import('../components/dashboard/AddQuestionForm.vue'),
        meta: {
          SharedAdmin: true,
          Verified: true
        },
      },
      {
        path: 'addExam',
        name: 'AddExam',
        component: () => import('../components/dashboard/AddExam.vue'),
        meta: {
          SharedAdmin: true,
          Verified: true
        },
      },
      {
        path: 'questionsList',
        name: 'QuestionsList',
        component: () => import('../components/dashboard/QuestionsList.vue'),
        meta: {
          SharedAdmin: true,
          Verified: true
        },
      },
      {
        path: 'sessionsList',
        name: 'SessionsList',
        component: () => import('../components/dashboard/SessionsList.vue'),
        meta: {
          Etudiant: true,
          Verified: true
        },
      },

      {
        path: 'subscriptions',
        name: 'ChoiceSubscriptionUser',
        component: () => import('../components/dashboard/ChoiceSubscriptionUser.vue'),
        meta: {
          Etudiant: true,
          Verified: true
        },
      },

      {
        path: 'subscriptionsinfo',
        name: 'subscriptionsInfo',
        component: () => import('../components/dashboard/subscriptionsInfo.vue'),
        meta: {
          Etudiant: true,
          Verified: true
        },
      },

      {
        path: 'playlistsList',
        name: 'PlaylistsList',
        component: () => import('../components/dashboard/PlaylistsList.vue'),
        meta: {
          Etudiant: true,
          Verified: true
        },
      },

      {
        path: 'examensList',
        name: 'ExamenList',
        component: () => import('../components/dashboard/SessionExamenList.vue'),
        meta: {
          Etudiant: true,
          Verified: true
        },
      },
      {
        path: 'residanat/examensList',
        name: 'ResidanatExamenList',
        component: () => import('../components/dashboard/Residana/user/ResidantSessionExamenList.vue'),
        meta: {
            Etudiant: true,
            Verified: true,
            Residanat: true
        },
      },
      {
        path: 'currentSession',
        name: 'CurrentSession',
        component: () => import('../components/dashboard/SessionTest.vue'),
        meta: {
          Etudiant: true,
          Verified: true
        },
      },
      {
        path: 'residanat/currentSession',
        name: 'ResidanatCurrentSession',
        component: () => import('../components/dashboard/Residana/user/ResidanatSessionTest.vue'),
        meta: {
            Etudiant: true,
            Verified: true,
            Residanat: true
        },
      },
      {
        path: 'reportList',
        name: 'ReportList',
        component: () => import('../components/dashboard/ReportList.vue'),
        meta: {
          SharedAdmin: true
        },
        
      },
      {
        path: 'SourceList',
        name: 'SourceList',
        component: () => import('../components/dashboard/SourceCRUD/SourceList.vue'),
        meta: {
          SharedAdmin: true
        },

      },
      {
        path: 'subscriptionprices',
        name: 'subscriptionPrices',
        component: () => import('../components/dashboard/subscriptionPrices.vue'),
        meta: {
          //Admin: true ,
          SuperAdmin : true
        },
        
      },
      {
        path: 'subscriptionList',
        name: 'SubscriptionList',
        component: () => import('../components/dashboard/SubscriptionList.vue'),
        meta: {
          //Admin: true,
          SuperAdmin : true
        },
        
      },
      {
        path: 'subscriptionModuleList',
        name: 'subscriptionModuleList',
        component: () => import('../components/dashboard/SubscriptionsModuleList.vue'),
        meta: {
          //Admin: true,
          SuperAdmin : true
        },

      },
      {
        path: 'modulesandcoursesList',
        name: 'ModulesAndCoursesList',
        component: () => import('../components/dashboard/ModulesAndCoursesList.vue'),
        meta: {
          SharedAdmin: true
        },

      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: () => import('../components/dashboard/Notifications.vue'),
        meta: {
          Etudiant: true,
          Verified: true,
        }
      },
      {
        path: 'notifications/:id',
        name: 'NotificationDetails',
        component: () => import('../components/dashboard/NotificationDetails.vue'),
        meta: {
          Etudiant: true,
          Verified: true,
        }
      }
    ],
  },
  // {
  //   path: '/currentSession',
  //   name: 'CurrentSession',
  //   component: () => import('../components/dashboard/SessionTest.vue'),
  //   meta: {
  //     Auth: true
  //   },
  // }

  // {
  //   path: '/:pathMatch(.*)*',
  //   name: 'Error404',
  //   component: () => import('../components/Error/Error404.vue')
  // }

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

/*router.beforeEach((to, from, next) => {
  if(Cookies.get("access_token")) {
    store.dispatch('getCurrentUser')
    .then((data) => {
      
      next();
    })
  }else {
    next({
      path: '/login'
    })
  }
})

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.Auth)) {
    if (!store.state.IsLogin) {
      next({
        path: '/login'
      })
    }
    else {
      next()
    }
  }
  else {
    next()
  }

})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.BeforeAuth)) {

    if (store.state.IsLogin) {
      next({
        path: '/dashboard'
      })
    } else {
      next()
    }
  } else {
    next()
  }
});


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.Sub) && (store.state.user.role === 'etudiant')) {
    axiosIns.get(process.env.VUE_APP_API_BASE_URL+"/getusersubscribes", {
      headers: {
        Authorization: "Bearer" + store.state.access_token,
      },
    })
      .then(({ data }) => {
        store.commit("set_subscriptions", data.result);
        store.commit("set_subscriptionsModule", data.modules);
        if (to.matched.some(record => record.meta.Residanat)) {

          if (!(store.getters.is_residatant_subscriber == false)) {
              next({
                  path: '/'
              })
          }
        }
      })
      .catch(() => {
      });
      var tmp = false;
      axios
        .get(process.env.VUE_APP_API_BASE_URL+"/moduleofyear/" + store.state.subscribeNow, {
          headers: {
            Authorization: "Bearer" + store.getters.get_token,
          },
        })
        .then(({ data }) => {
          data.modules.forEach(element => {
            if (store.getters.get_subscriptionsModule.includes(element.id)){
              tmp = true;
            }
          });
          store.commit("set_moduleSubscribed", tmp);
        })
        .catch(() => {
          // 
        });

    next()
  }else{
    next()
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.Sub) && (store.state.user.role === 'etudiant')) {
    axiosIns.get(process.env.VUE_APP_API_BASE_URL+"/auth/user-profile", {
      headers: {
        Authorization: "Bearer" + store.state.access_token,
      },
    })
      .then(() => {
        next()
      })
      .catch(function (error) {
        if (error.response.status == 401) {
          store.commit('set_token', null)
          store.commit('set_user', null)
          store.commit('set_islogin', false)
          store.commit('set_image', null)
          store.commit('set_darkMode', false)
          store.commit('set_subscriptions', [])
          store.commit("set_subscriptionsModule", []);
          store.commit('set_subscribeNow', null)
          next({
            path: '/login'
          })
          ElNotification({
            title: 'Expiration de session',
            message: 'Comme notre site web n\'accepte qu\'une seule connexion à la fois pour le même compte, votre session n\'est plus valide car une nouvelle connexion a été détectée.',
            duration: 0,
            type: 'warning',
            position: 'bottom-right',
          })
        }else{
          next()
        }
      });


    
  } else {
    next()
  }
  
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.Admin)) {

    if (!(store.state.user.role === 'admin')) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else {
    next()
  }

});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.SuperAdmin)) {

    if (!(['meziani.imad98@gmail.com', 'youcefbouab03@gmail.com', 'chaouchetarek08@gmail.com'].includes(store.state.user.email) )) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else {
    next()
  }

});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.Etudiant)) {

    if (!(store.state.user.role === 'etudiant')) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else {
    next()
  }

});


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.Verified)) {

    if (store.state.user.email_verified_at === null) {
      next({
        path: '/validation_email'
      })
    } else {
      next()
    }
  } else {
    next()
  }

});*/


/*router.beforeEach((to, from, next) => {

  if (Cookies.get("access_token")) {
    store.dispatch('getCurrentUser')
      .then((data) => {
        // Now we have the current user, proceed with the other route guards

        // Auth Guard
        if (to.matched.some(record => record.meta.Auth)) {
          if (!store.state.IsLogin) {
            return next({ path: '/login' });
          }
        }

        // BeforeAuth Guard
        if (to.matched.some(record => record.meta.BeforeAuth)) {
          if (store.state.IsLogin) {
            return next({ path: '/dashboard' });
          }
        }

        // Sub Guard
        if (to.matched.some(record => record.meta.Sub) && store.state.user.role === 'etudiant') {
          axiosIns.get(process.env.VUE_APP_API_BASE_URL + "/getusersubscribes", {
            headers: { Authorization: "Bearer " + store.state.access_token },
          })
          .then(({ data }) => {
            store.commit("set_subscriptions", data.result);
            store.commit("set_subscriptionsModule", data.modules);

            if (to.matched.some(record => record.meta.Residanat)) {
              if (!store.getters.is_residatant_subscriber) {
                return next({ path: '/' });
              }
            }
          })
          .catch(() => {});

          axiosIns.get(process.env.VUE_APP_API_BASE_URL + "/moduleofyear/" + store.state.subscribeNow, {
            headers: { Authorization: "Bearer " + store.getters.get_token },
          })
          .then(({ data }) => {
            let isSubscribed = data.modules.some(module => store.getters.get_subscriptionsModule.includes(module.id));
            store.commit("set_moduleSubscribed", isSubscribed);
          })
          .catch(() => {});

        }

        // Admin Guard
        if (to.matched.some(record => record.meta.Admin)) {
          if (store.state.user.role !== 'admin') {
            return next({ path: '/' });
          }
        }

        // SuperAdmin Guard
        if (to.matched.some(record => record.meta.SuperAdmin)) {
          const superAdminEmails = ['meziani.imad98@gmail.com', 'youcefbouab03@gmail.com', 'chaouchetarek08@gmail.com'];
          if (!superAdminEmails.includes(store.state.user.email)) {
            return next({ path: '/' });
          }
        }

        // Etudiant Guard
        if (to.matched.some(record => record.meta.Etudiant)) {
          if (store.state.user.role !== 'etudiant') {
            return next({ path: '/' });
          }
        }

        // Email Verification Guard
        if (to.matched.some(record => record.meta.Verified)) {
          if (store.state.user.email_verified_at === null) {
            return next({ path: '/validation_email' });
          }
        }

        // Proceed if none of the conditions above require redirection
        next();

      })
      .catch(() => {
        // Handle errors (e.g., token expired or invalid) by redirecting to login
        next({ path: '/login' });
      });
  } else {
    console.log('Login')
    if (to.path !== '/login') {
      return next({ path: '/login' });
    }
    next();
    
  }
});*/


router.beforeEach(async (to, from, next) => {
  const accessToken = Cookies.get("access_token");

  if (accessToken) {
    try {
      if (!store.state.user) {
        // Only fetch user data if not already available
        await store.dispatch('getCurrentUser', helpers.getWebGLFingerprint());
      }

      // Define helper functions for role and permission checks
      const isAuthenticated = store.state.IsLogin;
      const isAdmin = store.state.user.role === 'admin';
      const isEtudiant = store.state.user.role === 'etudiant';
      //const isSuperAdmin = ['meziani.imad98@gmail.com', 'youcefbouab03@gmail.com', 'chaouchetarek08@gmail.com'].includes(store.state.user.email);
      const isSuperAdmin = store.state.user.role === 'superadmin';
      const isEmailVerified = store.state.user.email_verified_at !== null;

      const hasSharedAccess = isAdmin || isSuperAdmin;

      // Auth Guard
      if (to.matched.some(record => record.meta.Auth) && !isAuthenticated) {
        return next('/login');
      }

      // BeforeAuth Guard
      if (to.matched.some(record => record.meta.BeforeAuth) && isAuthenticated) {
        return next('/dashboard');
      }

      // Subscription Guard (for 'etudiant' users)
      if (to.matched.some(record => record.meta.Sub) && isEtudiant) {
        if (!store.state.subscriptions || !store.state.subscriptionsModule) {
          // Fetch subscription data if not already available
          const [subscriptions, moduleOfYear] = await Promise.all([
            axiosIns.get(`/getusersubscribes`),
            axiosIns.get(`/moduleofyear/${store.state.subscribeNow}`),
          ]);

          store.commit("set_subscriptions", subscriptions.data.result);
          store.commit("set_subscriptionsModule", subscriptions.data.modules);

          const isSubscribed = moduleOfYear.data.modules.some(module => 
            store.getters.get_subscriptionsModule.includes(module.id)
          );
          store.commit("set_moduleSubscribed", isSubscribed);
        }

        // Residanat Guard
        if (to.matched.some(record => record.meta.Residanat) && !store.getters.is_residatant_subscriber) {
          return next('/');
        }
      }

      // Admin Guard
      if (to.matched.some(record => record.meta.Admin) && !isAdmin) {
        return next('/');
      }

      // SuperAdmin Guard
      if (to.matched.some(record => record.meta.SuperAdmin) && !isSuperAdmin) {
        return next('/');
      }

      // Etudiant Guard
      if (to.matched.some(record => record.meta.Etudiant) && !isEtudiant) {
        return next('/');
      }

      // Shared Admin Guard (for routes accessible by both admin and superadmin)
      if (to.matched.some(record => record.meta.SharedAdmin) && !hasSharedAccess) {
        return next('/');
      }

      // Email Verification Guard
      if (to.matched.some(record => record.meta.Verified) && !isEmailVerified) {
        return next('/validation_email');
      }

      // Proceed to the route if all checks pass
      next();
    } catch (error) {
      console.error('Error in navigation guard:', error);
      return next('/login');
    }
  } else {
    // If no access token, redirect to login unless already on login page
    
    if (!to.matched.some(record => record.meta.BeforeAuth) && to.path !== '/login') {
      return next('/login');
    }
    next();
  }
});

export default router
